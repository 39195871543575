import { Box, Grid, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { fetchApi } from '../helpers/fetchApi';
import CircularProgress from '@mui/material/CircularProgress';

const DetalleDeVehiculo = () => {  
  const [vehiculo, setVehiculo] = useState<any>([]);
  const [cargando, setCargando] = useState(true);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');

  const patenteVehiculoDetalle = 'AF-164-TF'
  const urlGetVehiculoEstadoDetalle = `https://scaapi.virtec.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/GetVehiculoEstadoDetalle?patenteVehiculo=${patenteVehiculoDetalle}`;
  const getVehiculoEstadoDetalle = async() => {
    return await fetchApi(`${urlGetVehiculoEstadoDetalle}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
    });
  };

  const [orderColumn, setOrderColumn]= useState("Nombre");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(vehiculo, columnName, columnOrder);

    setVehiculo(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async () => {
    const [payload, error] = await getVehiculoEstadoDetalle();
    if(!error){
      setVehiculo(payload);
      setUltimoRefresco(new Date().toTimeString());
      setCargando(false);
    }
  }

  useEffect(() => {
    leer();
  }, []);
  


  return (
    <Dashboard title="Detalle de Vehiculo" refresco={leer}>
      <Grid container spacing={0}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="patenteVehiculo"
          label="Vehiculo"
          name="patenteVehiculo"
          autoComplete="patenteVehiculo"
          />
          <TextField
          margin="normal"
          required
          fullWidth
          id="chofer"
          label="Chofer"
          name="chofer"
          autoComplete="chofer"
          />
      
        <Button>Generar Permiso</Button>
        
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell onClick={(e)=> handleSortRequest('Nombre')}>
                    <TableSortLabel active={orderColumn == 'Nombre'} direction={orderColumn != 'Nombre' || orderDirection === "asc"? "asc" : "desc"}>
                      Nombre
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell align="right" onClick={(e)=> handleSortRequest('Dni')}>
                    <TableSortLabel active={orderColumn == 'Dni'} direction={orderColumn != 'Dni' || orderDirection === "asc"? "asc" : "desc"}>
                      Dni
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                    <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                    Contratista
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Vigencia_Descripcion')}>
                    <TableSortLabel active={orderColumn == 'Vigencia_Descripcion'} direction={orderColumn != 'Vigencia_Descripcion' || orderDirection === "asc"? "asc" : "desc"}>
                    Estado
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Tipo_Vigencia')}>
                    <TableSortLabel active={orderColumn == 'Tipo_Vigencia'} direction={orderColumn != 'Tipo_Vigencia' || orderDirection === "asc"? "asc" : "desc"}>
                    Tipo
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Requisito_Faltante')}>
                    <TableSortLabel active={orderColumn == 'Requisito_Faltante'} direction={orderColumn != 'Requisito_Faltante' || orderDirection === "asc"? "asc" : "desc"}>
                    Requisito Faltante
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                    <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                    Embarque
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Chofer')}>
                    <TableSortLabel active={orderColumn == 'Chofer'} direction={orderColumn != 'Chofer' || orderDirection === "asc"? "asc" : "desc"}>
                    Chofer
                    </TableSortLabel>                    
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {!cargando && (vehiculo.map((v: any, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row">
                      {v.Nombre}
                    </TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Dni}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Contratista}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Vigencia_Descripcion}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Tipo_Vigencia}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Requisito_Faltante}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Fecha}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{v.Chofer}</TableCell>
                  </TableRow>
                )))
              }
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default DetalleDeVehiculo;