import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import md5 from 'blueimp-md5';
import { useCookies } from "react-cookie";
import { fetchGetUsuario } from '../reducers/fetchWebService';
import { useNavigate } from 'react-router-dom';
import { setIndex } from '../reducers/componentesHome';
import { RootState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authConfig';
import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { fetchTenant } from '../reducers/fetchWebService';

const Login = () => {
  const theme = createTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies();
  const { indexCookie } = useSelector((state: RootState) => state.mostradorComponentes)

  const [ urlLogo, setUrlLogo ] = useState('');

  const { instance } = useMsal();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const errorLogin = queryParams.get('errorLogin');

    if (errorLogin == "1") {
      alert("Sus credenciales no están vinculadas a los grupos del sistema Controlite - contáctese con el área de sistema");
    }

    const traerTenant = async () => {
      let tenant = await fetchTenant(); 

      let urlLogo = "https://virtec.controlite.com.ar/Content/V2_Template/img/logo_" + tenant.Id_Tenant + ".png";

      setUrlLogo(urlLogo);
    }

    traerTenant();
    // Resto de tu lógica de inicio de sesión
  }, [location.state]);

  const handleIndex = async (id) => {
    if(!cookies.datosHome) {
      setCookie('datosHome', [
        {
          userId: cookies.Id_User,
          abiertos: [], 
          columnas: [ 6,6,6,6,6,6,6,6,6 ], // ancho
          componentes: [ '1', '2', '3', '4', '5', '6', '7', '8', '9' ], // orden
        }
      ], {maxAge: 99999999});
    } else {
      let arrayDatos = cookies.datosHome;
      
      const buscarUsuario = () => {
        arrayDatos?.forEach((element, index) => {
          if(element.userId === id){
            return index;
          }
        });
        return 0;
      }
      
      await dispatch(setIndex(buscarUsuario()));
      
    }
  }

  const handleSubmitAD = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log('loginRedirect -> Error', e);
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let user = data.get('email');
    let pass = md5(data.get('password'));

    let res = await fetchGetUsuario(user, pass);

    if(res.Id_User !== 0){
      setCookie('Id_User', res.Id_User);
      handleIndex(res.Id_User);
      navigate('/');
    }else{
      alert("Usuario o contraseña incorrectos");
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{backgroundColor: "#31A1E8", boxShadow: "0px 0px 0px 0px"}}>
        <Toolbar>
          <img src={urlLogo} alt="logo" style={{maxHeight:'78px'}} />
        </Toolbar>
      </AppBar>
    </Box>
    
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={6}>
          <Item>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5" color="primary" style={{color: "#2b4277", marginBottom: "63px"}}>
                  Ingreso Principal Tecpetrol
                </Typography>
                <Typography component="h1" variant="h5" color="primary" style={{color: "#31A1E8"}}>
                  Sistema de Control de Acceso
                </Typography>
                <Box component="form" onSubmit={handleSubmitAD} noValidate sx={{ mt: 1 }}>
                  <Typography component="h1" variant="h6">
                  Ingresar email @tecpetrol.com y su contraseña de red
                  </Typography>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{backgroundColor: "#31A1E8"}}
                  >
                    Ingresar
                  </Button>
                </Box>
              </Box>
            </Container>
          </Item>
        </Grid> */}

        <Grid item xs={12} sm={12} md={6}>
          <Item>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
              sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}
              >
                <Typography component="h1" variant="h5" color="primary" style={{color: "#2b4277"}}>
                  {/* Ingreso Secundario */}
                  Ingreso
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Ingrese su usuario"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{backgroundColor: "#31A1E8"}}
                  >
                    Ingresar
                  </Button>
                </Box>
              </Box>
            </Container>
          </Item>
        </Grid>
      </Grid>
    </ThemeProvider>
    </>
  );
}

export default Login;