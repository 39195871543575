/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { fetchApi } from '../helpers/fetchApi';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IngresoPeatonal from "../components/IngresoPeatonal";
import Modal from '@mui/material/Modal';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    card:{
      [theme.breakpoints.up('sm')]: {
        minWidth: 500,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 1000,
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 1075,
      },
    },
    box: {
      position: 'relative' as 'relative',
      top: '50%',
      left: '50%',
      bottom: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80wh',
      maxHeight: '80vh',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      // boxShadow: 24,
      padding: '1rem',
      overflow: 'auto'
    },
    stickyHeader: {
      backgroundColor: '#fff',
      position: 'sticky',
      top: 0,
    },
  }),
);

const estiloRojo = {
  color: "white",
  backgroundColor: "red",
  padding: '2px 10px',
}

const estiloAmarillo = {
  color: "white",
  backgroundColor: "#FFA600",
  padding: '2px 10px',
}

const estiloVerde = {
  color: "white",
  backgroundColor: "green",
  padding: '2px 10px',
}

export default function DetalleDeVehiculoComponente(props) {

  const classes = useStyles();

  
  const navigate = useNavigate();

  const [vehiculo, setVehiculo] = useState<any>([]);
  const [cargando, setCargando] = useState(true);
  const [dniPersonaParaPermiso, setDniPersonaParaPermiso] = useState<any>();

  const [openModalIngreso, setOpenModalIngreso] = useState(false);
  const handleOpenFormIngreso = () => setOpenModalIngreso(true);
  const handleCloseFormIngreso = () => setOpenModalIngreso(false);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const [radioSelectIndex, setRadioSelectIndex] = useState<any>();

  const patenteVehiculoProp = props.patenteVehiculo;
  const urlGetVehiculoEstadoDetalle = `https://scaapi.virtec.controlite.com.ar/webservices/SCAAppDataWebService_Web.asmx/GetVehiculoEstadoDetalle?patenteVehiculo=${patenteVehiculoProp}`;
  const getVehiculoEstadoDetalle = async() => {
    return await fetchApi(`${urlGetVehiculoEstadoDetalle}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': 'http://localhost:3000',
      },
    });
  };


  const handleRadio = (dni, index) => {
    if(radioSelectIndex === index) {
      setDniPersonaParaPermiso(null);
      setRadioSelectIndex(null) 
    } else {
      setDniPersonaParaPermiso(dni);
      setRadioSelectIndex(index)
    }
  }

  const [orderColumn, setOrderColumn]= useState("Nombre");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(vehiculo, columnName, columnOrder);

    setVehiculo(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  useEffect(() => {
    const leer = async () => {
      const [payload, error] = await getVehiculoEstadoDetalle();
      if(!error){
        setVehiculo(payload);
        setCargando(false);
      }
    }
    leer();
  }, []);


  return (
    <Grid item>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="div">
            Detalle de Vehiculo
          </Typography>
          <Box sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}>
          <TextField
          margin="normal"
          disabled
          fullWidth
          id="patenteVehiculo"
          label="Vehiculo"
          defaultValue={props.patenteVehiculo}
          name="patenteVehiculo"
          autoComplete="patenteVehiculo"
          />
          <TextField
          margin="normal"
          disabled
          fullWidth
          id="chofer"
          label="Chofer"
          defaultValue={props.choferVehiculo}
          name="chofer"
          autoComplete="chofer"
          />
      </Box>
        <Button onClick={() => {handleOpenFormIngreso()}}>Generar Permiso</Button>

          <Modal
          open={openModalIngreso}
          onClose={handleCloseFormIngreso}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box className={classes.box}>
            <IngresoPeatonal closeModal={handleCloseFormIngreso} dniPersonaDetalleVehiculo={dniPersonaParaPermiso} />
            </Box>
          </Modal>
   
          <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 400 }}>

            <FormControl fullWidth>
            <RadioGroup>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.stickyHeader}>
                <TableRow>
                  <TableCell width={0} padding={'none'}></TableCell>

                  <TableCell onClick={(e)=> handleSortRequest('Nombre')}>
                    <TableSortLabel active={orderColumn == 'Nombre'} direction={orderColumn != 'Nombre' || orderDirection === "asc"? "asc" : "desc"}>
                      Nombre
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell align="right" onClick={(e)=> handleSortRequest('Dni')}>
                    <TableSortLabel active={orderColumn == 'Dni'} direction={orderColumn != 'Dni' || orderDirection === "asc"? "asc" : "desc"}>
                      Dni
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                    <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                    Contratista
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Vigencia_Descripcion')}>
                    <TableSortLabel active={orderColumn == 'Vigencia_Descripcion'} direction={orderColumn != 'Vigencia_Descripcion' || orderDirection === "asc"? "asc" : "desc"}>
                    Estado
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Tipo_Vigencia')}>
                    <TableSortLabel active={orderColumn == 'Tipo_Vigencia'} direction={orderColumn != 'Tipo_Vigencia' || orderDirection === "asc"? "asc" : "desc"}>
                    Tipo
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Requisito_Faltante')}>
                    <TableSortLabel active={orderColumn == 'Requisito_Faltante'} direction={orderColumn != 'Requisito_Faltante' || orderDirection === "asc"? "asc" : "desc"}>
                    Requisito Faltante
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                    <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                    Embarque
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Chofer')}>
                    <TableSortLabel active={orderColumn == 'Chofer'} direction={orderColumn != 'Chofer' || orderDirection === "asc"? "asc" : "desc"}>
                    Chofer
                    </TableSortLabel>                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!cargando && (vehiculo.map((v: any, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    selected={index === radioSelectIndex ? true : false}
                    onClick={() => {
                      setRowSelectIndex(index);
                    }}
                  >
                    {v.Vigencia_Descripcion === 'Apto' ? 
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row"></TableCell>
                    :
                    <TableCell style={{ padding: 0, zIndex: 1 }} component="th" scope="row">
                    <FormControlLabel sx={{ padding: 0, margin: 0}} value={v.Dni} control={
                    <Radio size={'small'} value={v.Dni} checked={dniPersonaParaPermiso === v.Dni} onClick={ () => { handleRadio(v.Dni, index) } }/>
                    } label={""}/>
                    </TableCell>
                    }
                    <TableCell style={{ padding: '2px 10px' }} component="th" scope="row">
                      {v.Nombre}
                    </TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{v.Dni}</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{v.Contratista}</TableCell>

                    {v.Vigencia_Descripcion === 'Apto' ?
                    <TableCell align="center" style={estiloVerde}><p style={{ margin: 2 }}>{v.Vigencia_Descripcion}</p></TableCell>
                    :
                    <TableCell align="center" style={estiloRojo}><p style={{ margin: 2 }}>{v.Vigencia_Descripcion}</p></TableCell>
                    }

                    <TableCell style={{ padding: '2px 10px' }} align="right">{v.Tipo_Vigencia}</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">
                      <Box style={{ maxWidth: '15vw' }}>
                        <Tooltip title={v.Requisito_Faltante}>
                          <Typography noWrap align="left">{v.Requisito_Faltante}</Typography>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{v.Fecha}</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{v.Chofer}</TableCell>
                  </TableRow>
                )))
              }
              </TableBody>
            </Table>

            </RadioGroup>
            </FormControl>
            {cargando && <Box sx={{
              width: '100%',
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              margin: '1rem 0rem',
            }}>
              <CircularProgress/>
            </Box>}
          </TableContainer>
        </CardContent>
        <CardActions>
          {/* <Link to='/vehiculosEnCamino'>
            <Button size="small">Ir a pantalla completa</Button>
          </Link> */}
        </CardActions>
      </Card>
    </Grid>
  );
}